import React from 'react';
import st from "./Footer.module.css"
import {ReactComponent as Logo} from "../../image/logo.svg";
import {Link} from "react-router-dom";

const Footer = () => {
    const reloadPage = () => {
        console.log("reload")
        window.location.reload()
    }
    return (
        <div className={st.footer}>
            <div className={st.container}>
                <div className={st.information}>
                    <div className={st.logoAndText}>
                        <Link to='/home'><Logo className={st.logo}/></Link>
                        <div className={st.logoText}>
                            <span>РЕМОНТ</span>
                            <span>ИННОВАЦИИ</span>
                            <span>КОМФОРТ</span>
                            <span>УДОВОЛЬСТВИЕ</span>
                        </div>
                    </div>
                    <div className={st.links}>
                        <div className={st.cow}>
                            <Link to="/home"><span>Главная</span></Link>
                            <Link to='/projects'><span>Наши проекты</span></Link>
                            <Link to='/contacts'><span>Контакты</span></Link>
                            <a target="_blank" href='https://wa.me/79264795728?text=%D0%94%D0%BE%D0%B1%D1%80%D1%8B%D0%B9%20%D0%B4%D0%B5%D0%BD%D1%8C%21'><span>Мы в WhatsApp</span></a>
                            <Link to='/about-company'><span>О компании</span></Link>
                        </div>
                    </div>
                    <div className={st.companyInfo}>
                        <a href={"tel:89264795728"}>+7(926)479-57-28</a>
                        <span>riku.general@yandex.ru</span>
                        <span>Пн-Пт 9:00-19:00, Сб-Вс выходной</span>
                        <span>Г. Москва, улица Куусинена, 15к1</span>
                    </div>
                </div>
                <span className={st.rikuName}>2025  Москва</span>
            </div>
        </div>
    );
};

export default Footer;